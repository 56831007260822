import React from 'react';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    top: '20px',
    'z-index': 9,
  },
  button: {
    margin: theme.spacing(2, 0),
  },
}));

export default function SaveButton(props) {
  const { saveConfig, disabled } = props;
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      className={`${classes.button} ${classes.root}`}
      startIcon={<SaveIcon />}
      onClick={saveConfig}
      disabled={disabled}
    >
      Сохранить
    </Button>
  )
}