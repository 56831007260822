import React, { useState, useEffect, useRef } from 'react';
import cellStyles from './css/TableCell.module.css';

export const classByCellType = (type) => {
  let className = '';

  if (type === 'name') {
    className = 'configRowName'
  }

  if (type.includes('server_sizes')) {
    className = 'configRowSizes'
  }

  return className
}

export default function CustomTableCell(props) {
  const { data: initialData, setConfig, index, type, collapsedRowIndex } = props;
  const [ classFocused, setClassFocused ] = useState('')
  const [value, setValue] = useState(initialData)
  const underlineWrap = useRef(undefined)

  const addClassFocused = () => {
    setClassFocused(cellStyles.configRowUnderlineFocused)
    document.addEventListener('click', removeClassFocused)
  }

  const removeClassFocused = (e) => {
    if (!e.path.includes(underlineWrap.current)) {
      setClassFocused('')
      document.removeEventListener('click', removeClassFocused)
    }
  }

  useEffect(() => {
    if (initialData !== value) {
      setConfig(index, type, value, collapsedRowIndex);
    }
  })

  return (
    <div className={`${cellStyles.configRowItem} ${cellStyles[classByCellType(type)]}`}>
      <div ref={underlineWrap} className={`${cellStyles.configRowUnderline} ${classFocused}`} onClick={addClassFocused}>
        <input
          className={cellStyles.configRowItemTextInput}
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </div>
  )
}