import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core';

const labelClasses = makeStyles({
  shrink: {
    transform: 'none',
    top: 'unset',
    bottom: '4px',
    'margin-bottom': 0
  }
})

const inputClasses = makeStyles({
  root: {
    'margin-top': '0 !important',
  },
  input: {
    'padding-left': '30px',
  }
})

const inputWrapClasses = makeStyles({
  root: {
    'max-width': '300px',
    'margin-right': '5px',
  }
})

export default function ConfigSearcher(props) {
  const { setSearchFilter } = props;
  const { shrink } = labelClasses()
  const { input, root } = inputClasses()
  const changeHandler = (e) => {
    const value = e.target.value
    setTimeout(setSearchFilter, 0, value)
  }

  return (
    <TextField
      label={<SearchIcon />}
      className={`col ${inputWrapClasses().root}`}
      placeholder="Поиск"
      InputLabelProps={{
        shrink: true,
        classes: {
          shrink
        }
      }}
      InputProps={{
        onChange: changeHandler,
        classes: {
          input,
          root
        }
      }}
    />
  )
}