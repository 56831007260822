import React from 'react';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import style from './css/SizeRemover.module.css';

export default function SizeRemover(props) {
  const { index, pureSetConfigHook, collapsedRowIndex } = props;
  const deleteSize = () => {
    pureSetConfigHook(prevConfig => {
      prevConfig[index].sizes.splice(collapsedRowIndex, 1)
      return [...prevConfig]
    })
  }

  return (
    <button className={style.sizeRemover} onClick={deleteSize}>
      <DeleteOutlineIcon />
    </button>
  )
}