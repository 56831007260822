import React from 'react';
import { useHistory } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import Container from '@material-ui/core/Container';
import authService from '../../services/authService';
import { makeStyles } from '@material-ui/core';

const useContainerStyle = makeStyles({
  root: {
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    height: '100%',
  }
})

export default function SignIn() {
  const history = useHistory();
  const googleLoginSuccessCallback = (response) => {
    authService.login(response.tokenId)
      .then(() => {
        history.replace('/editor');
      })
      .catch(err => console.log(err))
  }
  const googleLoginFailureCallback = (response) => {
    console.log(response);
    alert("Fail");
  }
  const {root} = useContainerStyle();

  return (
    <Container component="main" maxWidth="xs" classes={{ root }}>
      <GoogleLogin
              clientId="641015681997-tl8edpin5mq9msuom4givav2er8iehtn.apps.googleusercontent.com"
          buttonText="Login"
          onSuccess={googleLoginSuccessCallback}
          onFailure={googleLoginFailureCallback}
      />
    </Container>
  );
}
