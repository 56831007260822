import React, { useEffect, useState } from 'react';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import NewConfigFieldCell from './NewConfigFieldCell';
import rowStyles from './css/TableConfigRow.module.css';
import newConfigStyles from './css/NewConfigFieldStyle.module.css';
import cellStyles from './css/TableCell.module.css';
import axios from 'axios';

export default function NewConfigField(props) {
  const { setNewConfigFlag, getNewConfig } = props;
  const defaultConfig = {
    name: null,
    serverSize: {
      width: null,
      height: null
    },
    sizes: [],
    isMultiTask: false,
    confidenceThreshold: null,
    isCustom: false,
    dictionaryCode: null,
    isGpu: false
  }
  const [config, setConfig] = useState(defaultConfig);
  const [validByCell, setValidByCell] = useState({
    name: 0,
    'serverSize.width': 0,
    'serverSize.height': 0,
    confidenceThreshold: 0,
    dictionaryCode: 0,
  })

  const setNewData = (dataType, data) => {
    setConfig(prevConfig => {
      if (dataType === 'serverSize') {
        prevConfig[dataType] = {
          ...prevConfig[dataType],
          ...data
        }
        return prevConfig
      }

      prevConfig[dataType] = data
      return {...prevConfig}
    })
  }

  const sendData = () => {
    !Object.values(validByCell).includes(0) && axios({
      method: 'post',
      url: '/api/Main/Add',
      data: config,
      headers: { Authorization: `Bearer ${localStorage.getItem('user')}` }
    })
      .then(() => {
      setNewConfigFlag(false)
      getNewConfig()
    })
      .catch((err) => {
      const status = err.response.status;

      if (status !== 200 || status !== 401) {
        alert('The add wasn`t successful!')
      }
      
      if (status === 401) {
        localStorage.removeItem('user')
        history.replace('/login');
      }
      console.log(err)
    })
  }

  return (
    <li className={rowStyles.tableRow}>
      <div className={cellStyles.iconBtnWrap}>
        <button disabled={Object.values(validByCell).includes(0)} onClick={sendData}>
          <CheckOutlinedIcon />
        </button>
      </div>
      <NewConfigFieldCell type={'text'} classType={'name'} dataType={'name'} dispatchData={setNewData} setValidByCell={setValidByCell} />
      <div className={rowStyles.serverSizesWrap}>
        <NewConfigFieldCell type={'text'} classType={'serverSize'} dataType={'serverSize.width'} dispatchData={setNewData} setValidByCell={setValidByCell} />
        <NewConfigFieldCell type={'text'} classType={'serverSize'} dataType={'serverSize.height'} dispatchData={setNewData} setValidByCell={setValidByCell} />
      </div>
      <NewConfigFieldCell type={'checkbox'} classType={''} dataType={'isMultiTask'} dispatchData={setNewData} />
      <NewConfigFieldCell type={'text'} classType={''} dataType={'confidenceThreshold'} dispatchData={setNewData} setValidByCell={setValidByCell} />
      <NewConfigFieldCell type={'checkbox'} classType={''} dataType={'isCustom'} dispatchData={setNewData} />
      <NewConfigFieldCell type={'text'} classType={''} dataType={'dictionaryCode'} dispatchData={setNewData} setValidByCell={setValidByCell} />
      <NewConfigFieldCell type={'checkbox'} classType={''} dataType={'isGpu'} dispatchData={setNewData} />
    </li>
  )
}