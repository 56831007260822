import React, { Fragment, useEffect, useState } from 'react'
import NewSizeInput from './NewSizeInput'

export default function NewSizeGenerator(props) {
  const { pureSetConfigHook, index, configId } = props
  const [size, setSize] = useState({})

  const calculateSize = (type, data) => {
    setSize(prevSize => {
      prevSize[type] = parseInt(data, 10)
      return prevSize
    })
  }

  useEffect(() => {
    return () => {
      if (Object.keys(size).length) {
        pureSetConfigHook(prevConfig => {
          const targetConfig = prevConfig.find((el) => el.id === configId)
          targetConfig.sizes = [...targetConfig.sizes, size]
          return [...prevConfig]
        })
      }
    }
  })

  return (
    <Fragment>
      <NewSizeInput calculateSize={calculateSize} type={'width'} autofocus={true} />
      <NewSizeInput calculateSize={calculateSize} type={'height'} />
    </Fragment>
  )
}