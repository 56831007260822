import React from 'react';
import cellStyles from './css/TableCell.module.css';
import CustomStringInput from './CustomStringInput';

export default function CollapsedRowCustomStringCell(props) {
  const { data, setConfig, type } = props;

  return (
    <div className={`${cellStyles.collapsedRowItem}`}>
      <CustomStringInput data={data} type={type} setConfig={setConfig} />
    </div>
  )
}