import React, { useEffect, useState } from 'react';
import cellStyles from './css/TableCell.module.css';
import {classByCellType} from './MainRowCustomStringCell';
import newConfigFieldStyle from './css/NewConfigFieldStyle.module.css';

import { Validator } from './CustomStringInput';

export default function NewConfigFieldCell(props) {
  const { type, classType, dataType, dispatchData, setValidByCell } = props;
  const [thisValid, setThisValid] = useState(0);
  useEffect(() => {
    if (dataType === 'dictionaryCode') {
      setThisValid(1)
    }
  }, [dataType])

  const sendDataToParent = (e) => {
    let data = getDataByInputType(e);
    let isValid = 1;
    data = calculateNestedProps(data);
    
    switch (dataType) {
      case 'name':
      case 'serverSize.width':
      case 'serverSize.height':
      case 'confidenceThreshold':
      case 'dictionaryCode':
        const validator = new Validator(dataType, e.target.value);
        isValid = validator.start();
        if (isValid) {
          setThisValid(1);
        } else {
          setThisValid(0);
        }
        break;
      default:
        break;
    }

    if (isValid) {
      dispatchData(dataType.split('.')[0], data);
    }
  }

  const getDataByInputType = (e) => {
    if (e.target.type === 'text') {
      return e.target.value
    }
    return e.target.checked
  }

  const calculateNestedProps = (data) => {
    if (Number.isInteger(parseInt(data, 10)) && dataType !== 'name' && dataType !== 'dictionaryCode') {
      data = parseInt(data, 10)
    }
    if (dataType.includes('serverSize')) {
      const propName = dataType.split('.')[1]
      return {
        [propName]: data
      }
    }
    return data
  }

  useEffect(() => {
    if (setValidByCell) {
      setValidByCell(prevState => {
        return {
          ...prevState,
          [dataType]: thisValid,
        }
      })
    }
  }, [thisValid])

  return (
    <div className={`${cellStyles.defaultRowItem} ${cellStyles.mainRowItem} ${cellStyles[classByCellType(classType)]}`}>
      <div className={`${type === 'text' ? newConfigFieldStyle.underline : ''} ${type === 'text' && !thisValid ? newConfigFieldStyle.underlineError : ''}`}>
        <input
          type={type}
          autoFocus={ dataType === 'name' ? true : false }
          className={`${newConfigFieldStyle.textInput}`}
          onChange={sendDataToParent} />
      </div>
    </div>
  )
}