import React from 'react';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import CloseIcon from '@material-ui/icons/Close';

export default function ConfigGenerator(props) {
  const { setNewConfigFlag, newConfigFlag } = props;
  const generateNewConfigField = () => {
    setNewConfigFlag(true)
  }
  const closeNewConfigField = () => {
    setNewConfigFlag(false)
  }

  return (
    <div>
      {
        newConfigFlag 
        ? <button onClick={closeNewConfigField}>
            <CloseIcon />
          </button>
        : <button onClick={generateNewConfigField}>
            <AddBoxRoundedIcon />
          </button>
      }
      
    </div>
  )
}