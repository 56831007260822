import React from 'react';
import tableCellStyles from './css/TableCell.module.css';
import columnHeader from './css/TableConfigColumnHeader.module.css'
import { classByCellType } from './CustomStringTableCell';

export default function TableConfigColumnHeader(props) {
  const { data } = props
  return (
    <li className={`${tableCellStyles.mainRowItem} ${tableCellStyles.defaultRowItem} ${tableCellStyles[classByCellType(data.type)]} ${columnHeader.columnHeader}`}>{data.title}</li>
  )
}