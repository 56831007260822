import React, { useEffect, useRef, useState } from 'react';
import tableCellStyles from './css/TableCell.module.css';

export default function CustomCheckboxTableCell(props) {
  const { data, setConfig, type } = props;
  const [checked, setChecked] = useState(data || false)
  const refContainer = useRef(data || false)

  useEffect(() => {
    if (refContainer.current !== checked) {
      setConfig(type, checked)
      refContainer.current = !refContainer.current
    }
  })

  return (
    <div className={`${tableCellStyles.mainRowItem} ${tableCellStyles.defaultRowItem}`}>
      <input 
        className={tableCellStyles.configRowCheckbox}
        type="checkbox"
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
      />
    </div>
  )
}