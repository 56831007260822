import axios from 'axios';

const authService = {
  login(token) {
    return axios({
      url: '/api/main/login',
      method: 'post',
      data: {
        token
      }
    })
      .then((response) => {
        const acessToken = response.data.token;
        if (acessToken) {
          localStorage.setItem("user", acessToken);
        }
        return response
      })
      .catch(err => console.log(err))
  }
}

export default authService;
