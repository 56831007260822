import React from 'react';
import style from './css/NewSizeInput.module.css'

export default function NewSizeInput(props) {
  const { calculateSize, type, autofocus } = props;
  const handleChange = (e) => {
    calculateSize(type, e.target.value)
  }

  return (
    <input className={style.newSizeInput} onChange={handleChange} autoFocus={autofocus ? true : false} />
  )
}