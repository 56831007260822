import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function EditorProtecter({ children, ...rest }) {
  return (
    <Route
      { ...rest }
      render={({ location }) => (
        localStorage.getItem('user') 
        ? children 
        : <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }} 
          />
      )}
    />
  )
}