import React, {
  useState,
  Fragment,
  useEffect
} from 'react';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

import CustomCheckboxTableCell from './CustomCheckboxTableCell';
import rowStyles from './css/TableConfigRow.module.css'
import cellStyles from './css/TableCell.module.css';
import columnHeader from './css/TableConfigColumnHeader.module.css'
import MainRowCustomStringCell from './MainRowCustomStringCell';
import CollapsedRowCustomStringCell from './CollapsedRowCustomStringCell';
import NewSizeGenerator from './NewSizeGenerator';
import SizeRemover from './SizeRemover';

export default function TableConfigRow(props) {
  const { 
    config,
    setConfig,
    pureSetConfigHook,
    setValidByRows,
    setConfigToDelete,
  } = props;
  const [open, setOpen] = useState(false);
  const [addSizeFlag, setAddSizeFlag] = useState(false)
  const [validByCells, setValidByCells] = useState({
    name: true,
    'serverSize.width': true,
    'serverSize.height': true,
    'confidenceThreshold': true,
    'dictionaryCode': true,
  });

  const handleCollapse = () => {
    setOpen((prevState) => {
      if (prevState) {
        setAddSizeFlag(false)
      }
      return !prevState
    })
  }

  useEffect(() => {
    setValidByRows(prevState => {
      const index = prevState.findIndex((el) => el.id === config.id)

      if (index === -1) {
        return [
          ...prevState,
          {
            id: config.id,
            valid: !Object.values(validByCells).includes(false),
          }
        ]
      }

      prevState[index] = {
        ...prevState[index],
        valid: !Object.values(validByCells).includes(false),
      }

      return [...prevState]
    })
  }, [validByCells])

  return (
    <Fragment>
      <li className={`${rowStyles.tableRow} ${open ? rowStyles.disableCellBorder : ''}`}>
        <div className={cellStyles.iconBtnWrap}>
          <IconButton aria-label="expand row" size="small" onClick={handleCollapse}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
        <MainRowCustomStringCell data={config.name} type={'name'} setConfig={setConfig} setValidByCells={setValidByCells} validByCells={validByCells.name} />
        <div className={rowStyles.serverSizesWrap}>
          <MainRowCustomStringCell data={config.serverSize.width} type={'serverSize.width'} setConfig={setConfig} align={'center'} setValidByCells={setValidByCells} validByCells={validByCells['serverSize.width']} />
          <MainRowCustomStringCell data={config.serverSize.height} type={'serverSize.height'} setConfig={setConfig} align={'center'} setValidByCells={setValidByCells} validByCells={validByCells['serverSize.height']} />
        </div>
        <CustomCheckboxTableCell data={config.isMultiTask} type={'isMultiTask'} setConfig={setConfig} />
        <MainRowCustomStringCell data={config.confidenceThreshold} type={'confidenceThreshold'} setConfig={setConfig} align={'center'} setValidByCells={setValidByCells} validByCells={validByCells.confidenceThreshold} />
        <CustomCheckboxTableCell data={config.isCustom} type={'isCustom'} setConfig={setConfig} />
        <MainRowCustomStringCell data={config.dictionaryCode} type={'dictionaryCode'} setConfig={setConfig} align={'center'} setValidByCells={setValidByCells} validByCells={validByCells.dictionaryCode} />
        <CustomCheckboxTableCell data={config.isGpu} type={'isGpu'} setConfig={setConfig} />
        <div className={`${cellStyles.iconBtnWrap} ${cellStyles.iconDelete}`}>
          <button onClick={() => setConfigToDelete({name: config.name, id: config.id})}>
            <DeleteOutlineIcon />
          </button>
        </div>
      </li>
      {
        open
          ?
          <div className={`${rowStyles.collapsed} pl-4 pt-2 pb-3`}>
            <div className={`${cellStyles.configRowItem} ${columnHeader.columnHeader} ${rowStyles.collapsedTitle}`}>Размеры:</div>
            <div className={`${rowStyles.collapsedRowTable} ml-2`}>
              <ul className={rowStyles.collapsedHeaderTable}>
                <li className={`${cellStyles.configRowSizes} ${columnHeader.columnHeader}`}>Длина</li>
                <li className={`${cellStyles.configRowSizes} ${columnHeader.columnHeader}`}>Высота</li>
              </ul>
              <ul>
                {
                  config.sizes.map((size, collapsedRowIndex) => (
                    <li key={Math.random()} className={`${rowStyles.serversWrap}`}>
                      <CollapsedRowCustomStringCell data={size.width} type={'sizes.width'} setConfig={setConfig} collapsedRowIndex={collapsedRowIndex} align={'center'} />
                      <CollapsedRowCustomStringCell data={size.height} type={'sizes.height'} setConfig={setConfig} collapsedRowIndex={collapsedRowIndex} align={'center'} />
                      <SizeRemover pureSetConfigHook={pureSetConfigHook} collapsedRowIndex={collapsedRowIndex} />
                    </li>
                  ))
                }
                {
                  addSizeFlag
                  ? <li className={`${rowStyles.serverSizesWrap}`}>
                      <NewSizeGenerator pureSetConfigHook={pureSetConfigHook} configId={config.id} />
                    </li>
                  : ''
                }
                <li className={`${rowStyles.serverSizesWrap}`}>
                  {
                    addSizeFlag 
                    ? <button onClick={() => setAddSizeFlag(false)}>
                        <CheckOutlinedIcon />
                      </button>
                    : <button onClick={() => setAddSizeFlag(true)}>
                        <AddIcon />
                      </button>
                  }
                </li>
              </ul>
            </div>
          </div>
          : ''
      }
    </Fragment>
  )
}