import React from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom';

export default function DeleteConfigDialog(props) {
  const { configToDelete, setConfigToDelete, getNewConfig, setValidByRows } = props;
  const history = useHistory();
  const deleteConfig = () => {
    axios({
      method: 'delete',
      url: `api/main/delete?id=${configToDelete.id}`,
      headers: { Authorization: `Bearer ${localStorage.getItem('user')}` }
    })
      .then(() => {
        getNewConfig()

        setValidByRows(prevState => {
          const index = prevState.findIndex((el) => el.id === configToDelete.id)
          prevState.splice(index, 1)
    
          return [...prevState]
        })

        setConfigToDelete(false);
      })
      .catch((err) => {
        const status = err.response.status;

        if (status !== 200 || status !== 401) {
          alert('The delete wasn`t successful!')
        }

        if (status === 401) {
          localStorage.removeItem('user');
          history.replace('/login');
        }
        console.log('Failed to delete config', err)
      })
  }

  return (
    <Dialog
      open={!!configToDelete}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Удалить параметры конфигурации?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Удалит конфигурацию этой сети: <b>{configToDelete.name}</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setConfigToDelete(false)} color="primary">
          Отмена
        </Button>
        <Button onClick={deleteConfig} color="primary" autoFocus>
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  )
}