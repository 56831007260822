import React, { Component } from 'react';
import { 
  Redirect,
  Route,
  Switch
} from 'react-router';
import 'bootstrap';

import LoginPage from './components/component-login';
import EditorProtector from './components/component-editor/EditorProtector.js';
import EditorPage from './components/component-editor/EditorPage.js';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Switch>
        <Route exact path="/">
          { localStorage.getItem('user') ? <Redirect to='/editor' /> : <Redirect to='login' /> }
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <EditorProtector path="/editor">
          <EditorPage />
        </EditorProtector>
      </Switch>
    );
  }
}
