import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Container } from '@material-ui/core';

import {
  makeStyles,
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles';
import 'react-virtualized/styles.css';

import ConfigTable from './ConfigTable.js';
import ConfigPageHeader from './ConfigPageHeader';
import SaveButton from './SaveButton';
import DeleteConfigDialog from './DeleteConfigDialog';

const useStyle = makeStyles({
  'app-background': {
    'min-height': '100%',
    color: 'rgba(0, 0, 0, 0.65)',
    'font-family': `'Roboto', sans-serif`,
    'background-color': '#f0f2f5',
    padding: '24px',
  },
  editorPageBackground: {
    width: '100%',
    border: '1px solid #dee2e6 !important',
    padding: '24px',
    'margin-top': '16px',
    'border-radius': '5px',
    'background-color': '#fff'
  },
  editorHeader: {
    margin: 0,
    'font-size': '1.3em',
    'margin-bottom': '24px',
    'font-weight': 600,
    color: '#000000d9',
    display: 'inline-block'
  }
})

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        'font-size': '0.78125rem',
      },
    },
  },
});

export default function EditorPage() {
  const [config, setConfig] = useState([]);
  const [filteredConfig, setFilteredConfig] = useState(config);
  const [searchFilter, setSearchFilter] = useState('');
  const [newConfigFlag, setNewConfigFlag] = useState(false);
  const [validByRows, setValidByRows] = useState([]);
  const [configToDelete, setConfigToDelete] = useState(false);

  const classes = useStyle();
  const history = useHistory();
  const authHeader = { Authorization: `Bearer ${localStorage.getItem('user')}` }
  const badTokenHandler = (err) => {
    if (err.response.status === 401) {
      localStorage.removeItem('user')
      history.replace('/login');
    }
  }
  const getConfig = () => {
    axios({
      method: 'get',
      url: '/api/main/get',
      headers: authHeader
    })
      .then((response) => {
        setConfig(response.data)
      })
      .catch((err) => {
        badTokenHandler(err)
        console.log(err)
      })
  }
  const saveConfig = () => {
    axios({
      method: 'post',
      url: '/api/main/save',
      data: config,
      headers: authHeader
    })
      .then(() => {
        alert('The save was successful.')
        getConfig()
      })
      .catch((err) => {
        alert('The save wasn`t successful!')
        badTokenHandler(err)
        console.log('Failed to save config', err)
      })
  }
  const filterConfig = (filterString) => {
    if (config) {
      const filtered = config.filter(config => config.name.includes(filterString))
      setFilteredConfig(filtered)
    }
  }

  useEffect(() => {
    getConfig()
  }, [])

  useEffect(() => {
    filterConfig(searchFilter)
  }, [searchFilter, config])

  return (
    useMemo(() => {
      return (
        <Fragment>
          <div className={classes['app-background']}>
            <Container maxWidth="lg">
              <section className={classes.editorPageBackground}>
                <ConfigPageHeader
                  setSearchFilter={setSearchFilter}
                  setNewConfigFlag={setNewConfigFlag}
                  newConfigFlag={newConfigFlag}
                />
                {
                  validByRows.find(el => !el.valid)
                    ? <SaveButton saveConfig={saveConfig} disabled={true} />
                    : <SaveButton saveConfig={saveConfig} disabled={false} />
                }
                <ThemeProvider theme={theme}>
                  <ConfigTable
                    config={filteredConfig}
                    setConfig={setConfig}
                    newConfigFlag={newConfigFlag}
                    setNewConfigFlag={setNewConfigFlag}
                    getNewConfig={getConfig}
                    setValidByRows={setValidByRows}
                    setConfigToDelete={setConfigToDelete}
                  />
                </ThemeProvider>
              </section>
            </Container>
          </div>
          <DeleteConfigDialog
            configToDelete={configToDelete}
            setConfigToDelete={setConfigToDelete}
            getNewConfig={getConfig}
            setValidByRows={setValidByRows}
          />
        </Fragment>
      )
    }, [filteredConfig, newConfigFlag, validByRows, configToDelete])
  )
}
