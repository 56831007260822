import React  from 'react';
import ConfigSearcher from './ConfigSearcher';
import ConfigGenerator from './ConfigGenerator';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles({
  editorHeader: {
    margin: 0,
    'font-size': '1.6em',
    'margin-bottom': '24px',
    'font-weight': 600,
    color: '#000000d9',
  }
})

export default function ConfigPageHeader(props) {
  const { setSearchFilter, setNewConfigFlag, newConfigFlag } = props;
  const classes = useStyle();

  return (
    <div className='row align-items-center w-100 m-0'>
      <h1 className={`col mb-0 ${classes.editorHeader} pl-0`}>Редактор конфигураций</h1>
      <ConfigSearcher
        setSearchFilter={setSearchFilter}
      />
      <ConfigGenerator 
        setNewConfigFlag={setNewConfigFlag} 
        newConfigFlag={newConfigFlag}  
      />
    </div>
  )
}