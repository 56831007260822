import React, { useState, useEffect, useRef } from 'react';
import cellStyles from './css/TableCell.module.css';

export class Validator {
  constructor(type, value) {
    this.type = type;
    this.value = value;
  }

  start() {
    return this.validate();
  }

  validate() {
    if (this.getIsValid()) {
      return 1;
    } else {
      return 0;
    }
  }

  getIsValid() {
    if (this.type === 'name' && this.value.length >= 2) {
      return 1;
    }
    if (this.type !== 'name' && this.value.toString().length) {
      return 1;
    }
    if (this.type === 'dictionaryCode') {
      return 1;
    }
    return 0;
  }
}

export default function CustomStringInput(props) {
  const { data: initialData, setConfig, type, collapsedRowIndex, setValidByCells } = props;
  const [value, setValue] = useState(initialData);
  const [classFocused, setClassFocused] = useState('');
  const [thisValid, setThisValid] = useState(1);
  const underlineWrap = useRef(undefined);

  const addClassFocused = () => {
    setClassFocused(cellStyles.configRowUnderlineFocused)
    document.addEventListener('click', removeClassFocused)
  }

  const removeClassFocused = (e) => {
    if (!e.path.includes(underlineWrap.current)) {
      setClassFocused('')
      document.removeEventListener('click', removeClassFocused)
    }
  }

  const handleChange = (e) => {
    let newValue = e.target.value;

    if (type !== 'name' && type !== 'dictionaryCode') {
      newValue = parseInt(newValue, 10);
      
      if (isNaN(newValue)) {
        newValue = '';
      }
    }

    setValue(newValue)
  }

  useEffect(() => {
    let isValid;

    switch (type) {
      case 'name':
      case 'serverSize.width':
      case 'serverSize.height':
      case 'confidenceThreshold':
      case 'dictionaryCode':
        const validator = new Validator(type, value);
        isValid = validator.start();
        if (isValid) {
          setThisValid(1);
        } else {
          setThisValid(0);
        }
        break;
      default:
        break;
    }

    if (initialData !== value && isValid) {
      setConfig(type, value, collapsedRowIndex);
    }
  }, [value])

  useEffect(() => {
    if (setValidByCells) {
      if (thisValid) {
        setValidByCells(prevValidState => (
          { ...prevValidState, [type]: true }
        ));
      } else {
        setValidByCells(prevValidState => (
          { ...prevValidState, [type]: false }
        ));
      }
    }
  }, [thisValid])

  return (
    <div ref={underlineWrap} className={`${ thisValid ? '' : cellStyles.configRowUnderlineError } ${cellStyles.configRowUnderline} ${cellStyles.defaultRowItem} ${classFocused}`} onClick={addClassFocused}>
      <input
        className={cellStyles.configRowItemTextInput}
        type="text"
        value={value}
        onChange={handleChange}
      />
    </div>
  )
}