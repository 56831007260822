import React, { useEffect } from 'react';
import cellStyles from './css/TableCell.module.css';
import CustomStringInput from './CustomStringInput';

export const classByCellType = (type) => {
  let className = '';

  if (type === 'name') {
    className = 'configRowName'
  }

  if (type.includes('server_sizes')) {
    className = 'configRowSizes'
  }

  return className
}

export default function MainRowCustomStringCell(props) {
  const { data, setConfig, type, setValidByCells } = props;

  return (
    <div className={`${cellStyles.defaultRowItem} ${cellStyles.mainRowItem} ${cellStyles[classByCellType(type)]}`}>
      <CustomStringInput data={data} type={type} setConfig={setConfig} setValidByCells={setValidByCells} />
    </div>
  )
}