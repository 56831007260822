import React from 'react';
import TableConfigRow from './TableConfigRow';
import TableConfigColumnHeader from './TableConfigColumnHeader';
import NewConfigField from './NewConfigField';

import configTableStyles from './css/ConfigTable.module.css';
import cellStyles from './css/TableCell.module.css';
import rowStyles from './css/TableConfigRow.module.css';

const headers = [
  {
    title: 'Имя',
    type: 'name'
  },
  {
    title: 'Серверный размер',
    type: 'server_sizes'
  },
  {
    title: 'Мультитаска',
    type: 'is_multi_task'
  },
  {
    title: 'Порог уверенности',
    type: 'conf_threshold'
  },
  {
    title: 'Пользовательский',
    type: 'is_custom'
  },
  {
    title: 'Словарь',
    type: 'dictionary_code'
  },
  {
    title: 'GPU',
    type: 'is_gpu'
  }
]

export default function ConfigTable(props) {
  const { config, setConfig, newConfigFlag, setNewConfigFlag, getNewConfig, setValidByRows, setConfigToDelete } = props;
  const modifyGlobalNetworkConfig = (configId) => {
    return (type, data, collapsedRowIndex) => {
      const parsedType = type.split('.')
      setConfig(prevConfig => {
        const targetRow = prevConfig.find((el) => el.id === configId)
        let buffer = targetRow;
        let setDataToTargetProperty;
        let k = 0;

        if (!Number.isInteger(collapsedRowIndex)) {
          setDataToTargetProperty = (i) => {
            if (i === 1) {
              buffer[parsedType[parsedType.length - 1]] = data
            } else {
              buffer = buffer[parsedType[k++]]
              setDataToTargetProperty(i - 1)
            }
          }
        } else {
          setDataToTargetProperty = (i) => {
            if (i === 1) {
              buffer[parsedType[parsedType.length - 1]] = data
            } else {
              buffer = buffer[parsedType[k++]][collapsedRowIndex]
              setDataToTargetProperty(i - 1)
            }
          }
        }

        setDataToTargetProperty(parsedType.length)
        return prevConfig
      })
    }
  }

  return (
    <div className={configTableStyles.table}>
      <ul className={`${configTableStyles.headerList} ${rowStyles.tableRow}`}>
        <li className={`${configTableStyles.iconBtnHead} ${cellStyles.iconBtnWrap}`}></li>
        {
          headers.map((header) => <TableConfigColumnHeader key={header.type} data={header} />)
        }
        <li className={`${configTableStyles.iconBtnHead} ${cellStyles.iconBtnWrap} ${cellStyles.iconDelete}`}></li>
      </ul>
      <ul className={configTableStyles.ul}>
        {
          newConfigFlag
            ? <NewConfigField setNewConfigFlag={setNewConfigFlag} getNewConfig={getNewConfig} />
            : ''
        }
        {
          config.map((data, index) => (
            <TableConfigRow
              key={data.id}
              config={data}
              setConfig={modifyGlobalNetworkConfig(data.id)}
              pureSetConfigHook={setConfig}
              setValidByRows={setValidByRows}
              setConfigToDelete={setConfigToDelete}
            />
          ))
        }
      </ul>
    </div>
  )
}